<template>
  <div class="product-sorts">
    <head-nav></head-nav>
    <product-img></product-img>
    <ProductBreadcrumb
      :router-list="breadcrumb"
      :toback-list="breadcrumbFirst"
    ></ProductBreadcrumb>
    <div class="product-sort">
      <div class="wrap">
        <div class="sort-left">
          <div class="sort-left-top">
            <h1 class="sort-left-title">
              <img
                :src="$t('product-sort.title-1')"
                alt=""
              />
            </h1>
          </div>
          <nav class="sort-left-nav">
            <ul>
              <li
                class="sort-left-list"
                :class="currentProductCategoryId === item.id?'sort-left-list-active':''"
                v-for="(item, index) in productCategorieList"
                :key="index"
                @click="onProductCategoryClick(item.id)"
              >
                {{item.name}}
              </li>
            </ul>
          </nav>
        </div>

        <div class="sort-right">

          <div
            class="sort-right-list"
            v-for="(item, index) in produnctGroupList"
            :key="index"
          >
            <div class="sort-right-listLeft">
              <img
                :src="item.image.publicUrl"
                alt=""
              />
            </div>
            <div class="sort-right-list-right">
              <h3 class="list-right-name">{{item.name}}</h3>
              <div class="list-right-content">
                <span
                  class="list-right-content-list"
                  v-for="(items, index) in item.products"
                  :key="index"
                >
                  <span @click="produnctGroupListItem(items.id)">
                    <i class="iconfont icon-yuandian"></i> {{ items.name }}
                  </span>
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import HeadNav from './head-nav';
import ProductBreadcrumb from './product-breadcrumb';
import ProductImg from './product-img';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    ProductBreadcrumb,
    ProductImg,
    Footers,
  },
  apollo: {
    productCategorieList: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              productCategorieList: allProductCategories {
                id
                name
              }
            }
          `;
        }
        return gql`
          query {
            productCategorieList: allEnProductCategories {
              id
              name
            }
          }
        `;
      },
    },
    produnctGroupList: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query($id: ID!) {
              produnctGroupList: allProductGroups(where: { productCategoryId: { id: $id } }) {
                id
                name
                products {
                  id
                  name
                }
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        return gql`
          query($id: ID!) {
            produnctGroupList: allEnProductGroups(where: { productCategoryId: { id: $id } }) {
              id
              name
              products {
                id
                name
              }
              image {
                publicUrl
              }
            }
          }
        `;
      },
      variables() {
        return {
          id: this.currentProductCategoryId,
        };
      },
      skip() {
        return !this.currentProductCategoryId;
      },
    },
  },
  data() {
    return {
      breadcrumb: [
        {
          path: '/',
          label: 'product-sort.home',
        },
        {
          path: '/product-sort',
          label: 'product-sort.classification',
        },
      ],
      breadcrumbFirst: [{}],
      currentProductCategoryId: '',
      productCategorieList: [],
      produnctGroupList: [],
      list: [],
    };
  },
  watch: {
    productCategorieList(value) {
      if (value.length > 0) {
        this.currentProductCategoryId = value[0].id;
      }
    },
  },
  methods: {
    onProductCategoryClick(productCategoryId) {
      if (productCategoryId === this.currentProductCategoryId) {
        return;
      }
      this.currentProductCategoryId = productCategoryId;
    },
    produnctGroupListItem(productId) {
      this.$router.push({
        name: 'product-details',
        params: {
          productId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .productBox .wrap .productNav {
  top: -530px !important;
}

.product-sort {
  margin-top: -450px;
  margin-bottom: 160px;
  .wrap {
    display: flex;
    flex: 1;
    .sort-left {
      margin-right: 20px;
      width: 250px;
      text-align: center;
      .sort-left-top {
        box-shadow: 10px 3px 30px 10px rgba(0, 0, 0, 0.1);
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        height: 80px;
        background: #fff;
        .sort-left-title {
          padding-top: 20px;
        }
      }

      .sort-left-nav {
        width: 100%;
        padding-bottom: 90px;
        background: #f4f4f7;
        box-shadow: 10px 3px 30px 10px rgba(0, 0, 0, 0.1);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        opacity: 0.9;
        .sort-left-list {
          width: 100%;
          text-align: center;
          height: 60px;
          line-height: 60px;

          &:hover {
            background: #f8d87c;
          }
        }

        .sort-left-list-active {
          background: #f8d87c;
        }
      }
    }

    .sort-right {
      width: 930px;
      .sort-right-list {
        margin-bottom: 20px;
        width: 100%;
        background: #fff;
        border-radius: 10px;
        box-shadow: 10px 3px 30px 10px rgba(0, 0, 0, 0.1);
        height: 200px;
        display: flex;
        flex: 1;
        &:hover {
          .sort-right-listLeft {
            img {
              transform: scale(1.2);
            }
          }
        }
        .sort-right-listLeft {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          width: 300px;
          overflow: hidden;
          img {
            max-width: 100%;
            transition: all 1000ms;
          }
        }

        .sort-right-list-right {
          width: 570px;
          margin: 0 auto;
          height: 200px;
          .list-right-name {
            height: 60px;
            line-height: 60px;
            font-size: 18px;
            font-weight: 500;
            border-bottom: 1px solid #eeeeee;
          }
          .list-right-content {
            &-list {
              position: relative;
              display: inline-block;
              margin: 14px 35px 0 0;
              font-size: 14px;
              color: #666;

              &:hover {
                cursor: pointer;
                color: #333;
              }

              .iconfont {
                position: relative;
                top: 0px;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
